<template>
  <div class="loan_item">
    <div class="inner inner1">
      <h4>Ventajas</h4>
      <div class="content">
        <div class="cell_item">
          <div class="item_top">
            <img src="@/assets/images/loanImg/loan_img1.png" alt="">
            <h3>Préstamo rápido</h3>
          </div>
          <p>Conseguir un préstamo con la solicitud si usted envía su información.</p>
        </div>
        <div class="cell_item">
          <div class="item_top">
            <img src="@/assets/images/loanImg/loan_img2.png" alt="">
            <h3>No hay garantía</h3>
          </div>
          <p>Le brindamos un préstamo de hasta $750.000 basado en su  capacidad de pago. </p>
        </div>
        <div class="cell_item">
          <div class="item_top">
            <img src="@/assets/images/loanImg/loan_img3.png" alt="">
            <h3>Máximo</h3>
          </div>
          <p>Los ciudadanos colombianos que cumplan la edad correspondiente pueden solicitarlo y no tener ningun inconveniente. </p>
        </div>
        <div class="cell_item">
          <div class="item_top">
            <img src="@/assets/images/loanImg/loan_img4.png" alt="">
            <h3>Tranquilidad</h3>
          </div>
          <p>Simple y rápido sin procesos complicados, le permite pedir dinero prestado de manera simple, pagar rápidamente y que pueda mantener un  crédito para cantidades grandesde préstamo.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 16px;
      line-height: 22px;
      // color: #286BD9;
    }
    h4{
      width: 100%;
      padding:00px calc((100% - 1100px) / 2) 0;
      background: linear-gradient(270deg, #4DD093 0%, #1DAF99 100%);
      color: white;
      line-height: 60px;
      font-size: 15px;
    }
    .content{
      padding: 100px calc((100% - 1100px) / 2);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .cell_item{
      box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.16);
      border-radius: 10px;
      padding: 15px;
      width: 500px;
      min-height: 140px;
      margin-top: 18px;
      .item_top{
        height: 22px;
        display: flex;
        img{
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }
      p{
        margin:20px 0 0px;
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 20px !important;
        display: flex;
        flex-direction: column;
        .cell_item{
          width: 100% !important;
          height: 100px;
        }
        img{
          margin-top: 0px;
          width: 300px;
          height: 220px;
        }
      }
    }
    .inner2{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;
          }
        }
        img{
          margin: -20px 0px 50px;
        }
      }
    }
  }
}
</style>
